/* eslint-disable max-lines */

import {MouseEvent} from 'react';
import { connect } from 'react-redux';

import {
    DeliveryDataItem,
    ProductContainerFunctions,
    ProductContainerMapStateProps,
    ProductContainerProps, ProductContainerState,
    ProductErpPrice,
} from 'Component/Product/Product.type';
import DeliveryDateQuery from 'Query/DeliveryDate.query';
import {AvailabilityData, DeliveryDate} from 'Query/DeliveryDate.type';
import {AttributeWithValue} from 'Query/ProductList.type';
import {
    mapDispatchToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import {showNotification} from 'Store/Notification/Notification.action';
import {NotificationType} from 'Store/Notification/Notification.type';
import {isSignedIn} from 'Util/Auth/IsSignedIn';
import fromCache from 'Util/Cache/Cache';
import {getNewParameters} from 'Util/ConfigurableProduct';
import {formattedDayEurope} from 'Util/DateFormat';
import {getNextWeekDates} from 'Util/GetNexWeekDates';
import {formatPrice} from 'Util/Price';
import {getVariantIndex} from 'Util/Product';
import {
    getMaxQuantity, getMinQuantity, getName, getPrice, getProductInStock,
} from 'Util/Product/Extract';
import {ConfigurableProductSelectedVariantValue, ProductQuantity} from 'Util/Product/Product.type';
import {getProductIncrement} from 'Util/ProductIncrement';
import {prepareQuery} from 'Util/Query';
import {executePost} from 'Util/Request/Request';
import {RootState} from 'Util/Store/Store.type';

export {
    mapDispatchToProps,
};

/** @namespace Steinkrueger/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ProductContainerMapStateProps => ({
    cartId: state.CartReducer.cartTotals.id || '',
    device: state.ConfigReducer.device,
    isWishlistEnabled: state.ConfigReducer.wishlist_general_active,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    selectedDeliveryDay: state.MyAccountCompanyReducer.selectedDeliveryDay,
});

/** @namespace Steinkrueger/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    containerFunctions: ProductContainerFunctions = {
        addToCart: this.addToCart.bind(this),

        // Used to update entered and selected state values
        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),
        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        setValidator: this.setValidator.bind(this),
        scrollOptionsIntoView: this.scrollOptionsIntoView.bind(this),
        updateAddToCartTriggeredWithError: this.updateAddToCartTriggeredWithError.bind(this),
        getDeliveryDate: this.getDeliveryDate.bind(this),
        handleDeliveryDate: this.handleDeliveryDate.bind(this),
        setDeliveryDate: this.setDeliveryDate.bind(this),
        getErpPrice: this.getErpPrice.bind(this),
        hideTimer: this.hideTimer.bind(this),
        changePrice: this.changePrice.bind(this),
    };

    __construct(props: ProductContainerProps): void {
        const { parameters } = props;

        super.__construct?.(props);

        // @ts-ignore
        this.state = {
            // Used for customizable & bundle options
            enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
            selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
            addToCartTriggeredWithError: false,
            // Used for downloadable
            downloadableLinks: [],

            quantity: 1,

            // Used to add to the base price a selected option prices
            adjustedPrice: {},

            // Used for configurable product - it can be ether parent or variant
            selectedProduct: null,
            parameters,
            unselectedOptions: [],
            currentProductSKU: '',
            activeProduct: null,
            isTimerVisible: true,
            productScope: [],
            productErpPrices: [],
            selectedProductScope: '',
            isErpLoaded: false,
            isProductScopeSelectedByUser: false,
        };
    }

    componentDidMount(): void {
        const { product: { id, type_id }, isPDP } = this.props;
        const isLogged = isSignedIn();
        const isConfigurable = type_id === 'configurable';

        this.updateSelectedValues();
        this.updateAdjustedPrice();

        if (isLogged && id && !isConfigurable) {
            this.getErpPrice();

            if (isPDP) {
                this.getDeliveryDate();
            }
        }
    }

    componentDidUpdate(
        prevProps: ProductContainerProps,
        prevState: ProductContainerState,
    ): void {
        const {
            availableDeliveryData,
            isDeliveryDateLoading,
            selectedProductScope,
            selectedProduct,
            selectedDeliveryDate,
        } = this.state;

        const {
            product,
            isSignedIn,
            isPDP,
            isPlp,
            selectedDeliveryDay,
            product: { availability_data },
        } = this.props;

        const {
            product: prevProduct,
            isSignedIn: prevIsSignedIn,
            selectedDeliveryDay: prevSelectedDeliveryDay,
        } = prevProps;

        const { selectedProductScope: prevSelectedProductScope } = prevState;
        const { type_id } = product;

        if (product !== prevProduct) {
            const quantity = ProductContainer.getDefaultQuantity(this.props, this.state);

            if (quantity) {
                this.setQuantity(quantity);
            }

            this.getErpPrice();
            this.updateSelectedValues();
        }

        const isConfigurable = type_id === 'configurable';
        const isSelectedProduct = !!selectedProduct;
        const { selectedProduct: prevSelectedProduct } = prevState;

        if (product.id !== prevProduct.id) {
            this.getErpPrice();
        }

        if (isSignedIn !== prevIsSignedIn) {
            this.getErpPrice();
        }

        if (isPDP && !prevProps.product.attributes?.default_price_unit) {
            this.getErpPrice();
        }

        if (isPDP && isSignedIn && !availableDeliveryData && !isDeliveryDateLoading && !isConfigurable) {
            this.getDeliveryDate(isSelectedProduct);
        }

        if (isPDP && selectedDeliveryDay !== prevSelectedDeliveryDay) {
            this.setPdpDeliveryDate();
        }

        if (selectedProductScope !== prevSelectedProductScope) {
            this.setPriceOptions();
        }

        if (isConfigurable && selectedProduct?.id !== prevSelectedProduct?.id) {
            this.getErpPrice(isSelectedProduct);

            if (isPDP) {
                this.getDeliveryDate(isSelectedProduct);
                this.setPriceOptions();
            }
        }

        if (isPlp && isSignedIn) {
            const availabilityData = availability_data || [];

            if ((selectedDeliveryDay !== prevSelectedDeliveryDay)
                || (!selectedDeliveryDate && selectedDeliveryDay)) {
                const date = selectedDeliveryDay;

                if (date) {
                    const formattedDeliveryDate: string = date.split('T')[0];
                    const isAvailable = availabilityData.find((elem) => elem.date === formattedDeliveryDate);

                    if (isAvailable?.is_available) {
                        this.setDeliveryDate(formattedDeliveryDate);
                    }

                    if (isAvailable && !isAvailable.is_available && selectedDeliveryDate !== '') {
                        this.setDeliveryDate('');
                    }
                }
            }
        }

        this.changePrice('', null);
    }

    setQuantity(quantity: ProductQuantity): void {
        if (typeof quantity === 'object') {
            const { quantity: oldQuantity = {} } = this.state;

            this.setState({ quantity: { ...(oldQuantity as Record<number, number>), ...quantity } });
        } else {
            this.setState({ quantity: +quantity });
        }
    }

    changePrice(value: string, event: MouseEvent | null): void {
        const {
            productErpPrices,
            defaultPriceUnitLabel,
            erpPrice,
            selectedProductScope,
        } = this.state;

        if (event) {
            this.setState({
                isProductScopeSelectedByUser: true,
            });
        }

        const label = value === '' ? defaultPriceUnitLabel : value;
        const selectedPriceAttr = productErpPrices?.filter((price) => price.price_type === label);

        if (selectedPriceAttr && selectedPriceAttr.length) {
            const formattedPrice = formatPrice(Number(selectedPriceAttr[0].price_value));

            if (erpPrice !== formattedPrice
                || selectedProductScope !== selectedPriceAttr[0].price_type) {
                this.setState({
                    erpPrice: formattedPrice,
                    selectedProductScope: selectedPriceAttr[0].price_type,
                    isDataLoading: false,
                    defaultPriceUnitLabel: label,
                });
            }
        }
    }

    setPriceOptions(): void {
        const {
            product: {
                container_breakage,
            },
        } = this.props;
        const { selectedProductScope, selectedProduct } = this.state;
        const priceUnitValue = this.state.selectedProductScope;
        const selected_container_breakage = selectedProduct ? selectedProduct.container_breakage : container_breakage;

        if (selectedProductScope && selected_container_breakage) {
            const quantity = selectedProductScope === 'kg'
                ? 1 : getProductIncrement(selected_container_breakage, selectedProductScope);
            this.setQuantity(quantity);
        }

        if (priceUnitValue) {
            this.setPriceOption(['Price Unit', 'Price Unit Label']);
        }
    }

    setPriceOption(titles: string[]): void {
        const {selectedProductScope, productErpPrices} = this.state;

        const { options } = this.props.product;
        const { enteredOptions } = this.state;
        // eslint-disable-next-line fp/no-let
        let prevEnteredOptions = enteredOptions.filter((el) => el);

        titles.forEach((title) => {
            const priceUnit = options?.find(
                (item) => item.title === title,
            );

            if (priceUnit && selectedProductScope && productErpPrices) {
                const { uid } = priceUnit;
                const labelValue = productErpPrices.filter((elem) => elem.price_type
                    === selectedProductScope)[0].label;
                const selectedValue = title !== 'Price Unit' ? labelValue : selectedProductScope;

                const priceUnitOptions = [{
                    value: selectedValue,
                    uid,
                }];

                prevEnteredOptions = prevEnteredOptions.filter((el) => el.uid !== uid);
                prevEnteredOptions.push(priceUnitOptions[0]);
            }
        });

        this.setState({
            enteredOptions: prevEnteredOptions,
        });
    }

    hideTimer(): void {
        this.setState({isTimerVisible: false});
    }

    getErpPrice(isSelectedProduct = false) {
        const { product: { prices }, isPDP } = this.props;
        const id = isSelectedProduct ? this.state.selectedProduct?.id : this.props.product.id;
        const attributes = isSelectedProduct ? this.state.selectedProduct?.attributes : this.props.product.attributes;
        const priceList = this.state.selectedProduct?.prices ?? prices;
        const container_breakage = isSelectedProduct
            ? this.state.selectedProduct?.container_breakage : this.props.product.container_breakage;

        if (id !== undefined && priceList) {
            if (attributes?.default_price_unit && priceList) {
                const productScope: string[] = [];
                const productErpPrices: ProductErpPrice[] = [];
                const { show_kg_price_unit } = attributes;
                const default_price_unit_value = attributes.default_price_unit.attribute_value;
                // eslint-disable-next-line fp/no-let
                let default_price_unit_label = attributes.default_price_unit
                    .attribute_options[default_price_unit_value].label;
                const isKgEnabled = show_kg_price_unit !== undefined && show_kg_price_unit.attribute_value === '1';
                const isBreakOpen = container_breakage?.break_open;

                if (!isKgEnabled && default_price_unit_label === 'kg') {
                    default_price_unit_label = 'piece';
                }

                if (!isBreakOpen) {
                    default_price_unit_label = attributes.kolli_unit ? 'kolli' : 'piece';
                }

                const productAttributesScope = Object.keys(attributes);
                const allowedPriceAttributes = !isBreakOpen && attributes.kolli_unit
                    ? ['kolli_unit'] : ['kolli_unit', 'piece_unit'];

                if (isKgEnabled && isBreakOpen) {
                    allowedPriceAttributes.push('default_price_unit');
                    productErpPrices.unshift({
                        price_type: 'kg',
                        price_value: priceList.kg_price,
                        isDefault: false,
                        label: 'kg',
                    });
                    productScope.unshift('kg');
                }

                const priceAttributes = productAttributesScope.map((attr) => {
                    if (allowedPriceAttributes.includes(attr)) {
                        return attributes[attr];
                    }

                    return false;
                }).filter((item) => item);

                priceAttributes.forEach((attr) => {
                    if (typeof attr !== 'boolean') {
                        // eslint-disable-next-line fp/no-let
                        let formattedAttrCode = attr.attribute_code.split('_')[0];
                        const attrValue = attr.attribute_value;
                        const isDefault = attr.attribute_code === 'default_price_unit';
                        // eslint-disable-next-line fp/no-let
                        let isInList = false;

                        if (isDefault) {
                            formattedAttrCode = attr.attribute_options[attrValue].label;
                            productErpPrices.map((price) => {
                                if (price.price_type === formattedAttrCode) {
                                    const updatedPrice = price;
                                    updatedPrice.isDefault = true;
                                    isInList = true;

                                    return updatedPrice;
                                }

                                return price;
                            });
                        }

                        if (!isInList) {
                            const currentPrice = {
                                price_type: formattedAttrCode,
                                price_value: 0,
                                isDefault,
                                label: attr.attribute_options[attrValue].label,
                            };

                            Object.entries(priceList).forEach((val) => {
                                const [key, value] = val;

                                if (key.includes(formattedAttrCode)) {
                                    currentPrice.price_value = Number(value);
                                }
                            });

                            productScope.unshift(currentPrice.price_type);
                            productErpPrices.unshift(currentPrice);
                        }
                    }
                });

                const uniqErpPrices: string[] = [];

                const uniqProductErpPrices = productErpPrices.filter((item) => {
                    if (!uniqErpPrices.includes(item.price_type)) {
                        uniqErpPrices.push(item.price_type);

                        return true;
                    }

                    return false;
                });

                uniqProductErpPrices.map((item) => {
                    const updatedItem = item;

                    if (item.price_type === default_price_unit_label) {
                        updatedItem.isDefault = true;
                    }

                    return updatedItem;
                });

                this.setState({
                    productScope,
                    productErpPrices: uniqProductErpPrices,
                    defaultPriceUnitLabel: default_price_unit_label,
                });
            }

            if (attributes && Array.isArray(attributes) && !isPDP) {
                const productScope: string[] = ['kg'];
                const productErpPrices: ProductErpPrice[] = [{
                    price_type: 'kg',
                    price_value: priceList.kg_price,
                    isDefault: false,
                    label: 'kg',
                }];
                const defaultAttribute : AttributeWithValue[] = attributes.filter(
                    (item) => item.attribute_code === 'default_price_unit',
                );

                const currentPrice = {
                    price_type: 'kg',
                    price_value: Number(priceList?.kg_price),
                    isDefault: true,
                    label: 'kg',
                };

                if (defaultAttribute.length) {
                    const defaultPriceAttrLabel = defaultAttribute[0].attribute_options[0].label;
                    const defaultPriceAttrValue = Object.keys(priceList).filter(
                        (key) => key.includes(defaultPriceAttrLabel),
                    )[0];

                    currentPrice.price_type = defaultPriceAttrLabel;
                    // @ts-ignore
                    currentPrice.price_value = Number(priceList[defaultPriceAttrValue]);
                    currentPrice.isDefault = true;
                    currentPrice.label = defaultPriceAttrLabel;
                }

                productScope.push(currentPrice.price_type);
                productErpPrices.push(currentPrice);

                this.setState({
                    productScope,
                    productErpPrices,
                    defaultPriceUnitLabel: currentPrice.label,
                });
            }
        }
    }

    getDeliveryDate(isSelectedProduct = false) {
        const {
            product: {
                id,
            },
        } = this.props;

        const { selectedProduct } = this.state;

        const productId = isSelectedProduct ? selectedProduct?.id : id;

        if (!productId) {
            return false;
        }

        this.setState({isDeliveryDateLoading: true});

        const options = {
            productId,
        };

        const query = [DeliveryDateQuery.getQuery({...options})];

        executePost<{
            getAvailabilityData: AvailabilityData;
            data: DeliveryDate; }>(prepareQuery(query))
            .then(
                /** @namespace Steinkrueger/Component/Product/Container/ProductContainer/getDeliveryDate/then/catch/executePost/then */
                (data) => {
                    const availableDeliveryData = JSON.parse(data.getAvailabilityData.data);
                    const nextWeekDates: string[] = getNextWeekDates();
                    const missedDates: DeliveryDataItem[] = [];

                    nextWeekDates.forEach((item: string) => {
                        // eslint-disable-next-line max-len
                        const isInTheNextWeek = availableDeliveryData.filter((elem: DeliveryDataItem) => elem.date === item).length;

                        if (!isInTheNextWeek) {
                            missedDates.push({
                                date: item,
                                delivery_date_availability_id: new Date(item).getTime(),
                                is_available: false,
                            });
                        }
                    });

                    availableDeliveryData.push(...missedDates);
                    availableDeliveryData.sort(
                        // @ts-ignore
                        (a: DeliveryDataItem, b: DeliveryDataItem) => (new Date(a.date) - new Date(b.date)),
                    );

                    this.setState({ availableDeliveryData });
                    this.setState({isDeliveryDateLoading: false});
                    this.setPdpDeliveryDate();
                },
            )
            .catch(
                /** @namespace Steinkrueger/Component/Product/Container/ProductContainer/getDeliveryDate/then/catch/showNotification */
                (e) => showNotification(NotificationType.ERROR, __('Error fetching available Data!'), e),
            );

        return true;
    }

    setPdpDeliveryDate() {
        const { selectedDeliveryDay } = this.props;
        const { availableDeliveryData, selectedDeliveryDate } = this.state;
        const availableOptions = availableDeliveryData
            ? availableDeliveryData.filter((item: DeliveryDataItem) => item.is_available) : [];
        const formattedSelectedValue = selectedDeliveryDay ? formattedDayEurope(selectedDeliveryDay) : '';

        if (availableOptions.length) {
            const isGlobalDateAvailable = availableOptions.filter(
                (item) => item.date === formattedSelectedValue,
            ).length;

            if (selectedDeliveryDay && isGlobalDateAvailable) {
                this.setDeliveryDate(formattedSelectedValue);
            } else if (!selectedDeliveryDate) {
                this.setDeliveryDate(availableOptions[0].date);
            }
        }
    }

    handleDeliveryDate(e: MouseEvent, date: string) {
        e.stopPropagation();
        e.preventDefault();

        this.setDeliveryDate(date);
    }

    setDeliveryDate(date: string): void {
        const { options } = this.props.product;

        const deliveryDate = options?.find(
            (item) => item.title === 'Delivery Date',
        );

        if (deliveryDate) {
            const { uid } = deliveryDate;
            const enteredDeliveryOptions = [{
                value: date,
                uid,
            }];

            const { enteredOptions } = this.state;

            const prevEnteredOptions = enteredOptions.filter((el) => el.uid !== uid);

            prevEnteredOptions.push(enteredDeliveryOptions[0]);

            this.setState({
                enteredOptions: prevEnteredOptions,
                selectedDeliveryDate: date,
            });
        }
    }

    updateConfigurableVariant(
        key: string,
        value: ConfigurableProductSelectedVariantValue,
        checkEmptyValue = false,
    ): void {
        const { parameters: prevParameters } = this.state;
        const isParameters = Object.keys(this.state.parameters).length;

        const newParameters = getNewParameters(prevParameters, key, value);

        if (isParameters && newParameters === prevParameters) {
            return;
        }

        const { [ key ]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const { product: { variants = [], configurable_options = {} } } = this.props;
        const { selectedProduct } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        const newProduct = newIndex === -1 ? null : variants[ newIndex ];

        if (newProduct !== selectedProduct) {
            this.setState({
                // @ts-ignore
                selectedProduct: newProduct,
                parameters,
            });
        }
    }

    containerProps() {
        const {
            quantity,
            parameters,
            adjustedPrice,
            unselectedOptions,
            addToCartTriggeredWithError,
            availableDeliveryData,
            selectedDeliveryDate,
            erpPrice,
            isDataLoading,
            isDeliveryDateLoading,
            isTimerVisible,
            productScope,
            productErpPrices,
            selectedProductScope,
            selectedProduct,
            isProductScopeSelectedByUser,
        } = this.state;
        const {
            product,
            product: { options = [] } = {},
            configFormRef,
            device,
            isWishlistEnabled,
        } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const {
            price_range: priceRange = {},
            dynamic_price: dynamicPrice = false,
            type_id: type,
        } = activeProduct || {};

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getMaxQuantity(activeProduct),
            minQuantity: getMinQuantity(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, type, options]),
        };

        return {
            isWishlistEnabled,
            unselectedOptions,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            addToCartTriggeredWithError,
            availableDeliveryData,
            selectedDeliveryDate,
            erpPrice,
            isDataLoading,
            isDeliveryDateLoading,
            isTimerVisible,
            productScope,
            productErpPrices,
            selectedProductScope,
            selectedProduct,
            isProductScopeSelectedByUser,
            ...output,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ProductContainer,
);

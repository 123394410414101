/* eslint-disable max-lines */

import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AnyAction, Dispatch} from 'redux';

import {Page} from 'Component/Header/Header.config';
import ProductList from 'Component/ProductList/ProductList.component';
import {
    deliveryItems,
    ProductListContainerProps, ProductListContainerState,
} from 'Component/ProductList/ProductList.type';
import ProductPricesQuery from 'Query/ProductPrices.query';
import {ProductPrices} from 'Query/ProductPrices.type';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import {showNotification} from 'Store/Notification/Notification.action';
import {NotificationType} from 'Store/Notification/Notification.type';
import {ReactElement} from 'Type/Common.type';
import {scrollToTop} from 'Util/Browser';
import {formatPrice} from 'Util/Price';
import {IndexedProduct} from 'Util/Product/Product.type';
import {prepareQuery} from 'Util/Query';
import {ONE_MONTH_IN_SECONDS} from 'Util/Request/Config';
import {waitForPriorityLoad} from 'Util/Request/LowPriorityLoad';
import {executeGet} from 'Util/Request/Request';
import {RootState} from 'Util/Store/Store.type';

/** @namespace Steinkrueger/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Steinkrueger/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Steinkrueger/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    state: ProductListContainerState = {
        pagesCount: 1,
        pages: this.props.pages,
    };

    componentDidUpdate(prevProps: ProductListContainerProps): void {
        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (
            JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || currentPage !== prevPage
        ) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp) {
            return;
        }

        if (search !== prevSearch
            || currentPage !== prevPage
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    componentDidMount(): void {
        const {
            pages, isPreventRequest,
        } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            this.setState({ pagesCount: pagesLength });
        }

        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isPreventRequest) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    requestPage(currentPage = 1, isNext = false): void {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device,
            location: { pathname },
            isPlp,
        } = this.props;
        const { isPrefetchValueUsed } = window;

        const isSearch = pathname.includes(Page.SEARCH);
        const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch && !isPlp;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                filter,
                search,
                pageSize,
                currentPage,
            },
        };

        const { categoryIds: _, ...searchFilter } = filter;

        if (search && filter.categoryIds) {
            options.args.filter = searchFilter;
            window.isPrefetchValueUsed = false;
        }

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };

        if (!isPrefetched) {
            requestProductList(options);
        }

        if (!isWidget) {
            waitForPriorityLoad().then(
                /** @namespace Steinkrueger/Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
                () => requestProductListInfo(infoOptions),
            );

            if (!device.isMobile) {
                scrollToTop();
            }
        }
    }

    _getIsInfiniteLoaderEnabled(): boolean { // disable infinite scroll on mobile
        return false;
    }

    updateProductPrices(): void {
        const {
            pages,
            isSignedIn,
            filter: {
                customFilters,
            },
        } = this.props;

        if (isSignedIn) {
            const productIds = this.getProductIds(pages);

            if (productIds.length && JSON.stringify(productIds) !== JSON.stringify(this.state.productIds)) {
                this.setState({ productIds });
                const productOptions: {options: deliveryItems[]} = {
                    options: [{
                        product_id: '',
                    }],
                };

                if (customFilters && customFilters.delivery_date) {
                    const options: deliveryItems[] = [];
                    customFilters.delivery_date.forEach(() => {
                        productIds.forEach((elem) => {
                            options.push({
                                product_id: elem,
                            });
                        });
                    });
                    productOptions.options = options;
                } else {
                    productOptions.options = productIds.map((elem) => ({
                        product_id: elem,
                    }));
                }

                const query = [ProductPricesQuery.getQuery({ products: productOptions.options })];

                executeGet<ProductPrices>(prepareQuery(query), 'productPrices', ONE_MONTH_IN_SECONDS)
                    .then(
                        /** @namespace Steinkrueger/Component/ProductList/Container/ProductListContainer/updateProductPrices/then/catch/executeGet/then */
                        (ProductPrices) => {
                            const updatedPages: Record<number, IndexedProduct[]> = { ...pages};
                            Object.entries(updatedPages).filter(([, value]) => value.map((item) => {
                                const newItem = item;

                                const result = ProductPrices.productPrices.find(
                                    // @ts-ignore
                                    (product) => product.productId === item.id.toString(),
                                );
                                // @ts-ignore
                                newItem.erpPrice = formatPrice(Number(result.price));

                                return newItem;
                            }));

                            if (JSON.stringify(this.getProductIds(pages)) !== JSON.stringify(this.state.productIds)) {
                                this.setState({pages: updatedPages});
                            }
                        },
                    )
                    .catch(
                        /** @namespace Steinkrueger/Component/ProductList/Container/ProductListContainer/updateProductPrices/then/catch/showNotification */
                        (e) => showNotification(NotificationType.ERROR, __('Error fetching available Data!'), e),
                    );
            }
        }
    }

    getProductIds(pages?: Record<number, IndexedProduct[]>): string[] {
        const productIds: string[] = [];

        if (!pages) {
            return [];
        }

        Object.entries(pages).filter(([, value]) => value.forEach((item) => {
            if (item.id) {
                productIds.push(item.id.toString());
            }
        }));

        return productIds;
    }

    containerProps() {
        const {
            isPaginationEnabled: defaultIsPaginationEnabled = false,
        } = ProductListContainer.defaultProps;
        const {
            device,
            isLoading,
            isPaginationEnabled = defaultIsPaginationEnabled,
            isWidget = false,
            mix,
            selectedFilters,
            title = '',
            totalPages,
            isPlp,
            pages,
        } = this.props;

        const productIdsProps = this.getProductIds(pages);
        const productIdsState = this.getProductIds(this.state.pages);

        const isPagesUpdatedFromProps = JSON.stringify(productIdsProps) !== JSON.stringify(productIdsState);

        const updatedPages = isPagesUpdatedFromProps || !this.state.pages ? pages : this.state.pages;

        return {
            device,
            isLoading,
            isPaginationEnabled,
            isWidget,
            mix,
            pages: updatedPages,
            selectedFilters,
            title,
            totalPages,
            currentPage: this._getPageFromUrl(),
            isShowLoading: this._isShowLoading(),
            isVisible: this._isVisible(),
            isPlp: !!isPlp,
            requestPage: this.requestPage,
            // disable this property to enable infinite scroll on desktop
            isInfiniteLoaderEnabled: this._getIsInfiniteLoaderEnabled(),
        };
    }

    render(): ReactElement {
        return (
            <ProductList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
        RouteComponentProps & ProductListContainerProps
        >,
    ),
);
